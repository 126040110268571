export function useLoading() {
  const isLoading = ref(false)

  const loading = () => {
    isLoading.value = true
  }
  const load = () => {
    isLoading.value = false
  }

  return {
    isLoading,
    loading,
    load
  }
}
